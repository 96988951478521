import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import {
  aboutURL,
  contactUsURL,
  faqsURL,
  homeURL,
  privacyPolicyURL,
  productsURL,
  termsURL,
  whereToBuyURL,
  recipesURL,
} from "./components/helpers/constant-words";
import Header from "./components/Header/Header";
import Loader from "./components/Loader/Loader";

const Home = lazy(() => import("./pages/Home/Home"));
const About = lazy(() => import("./pages/About/About"));
const ContactUs = lazy(() => import("./pages/ContactUs/ContactUs"));
const WhereToBuy = lazy(() => import("./pages/WhereToBuy/WhereToBuy"));
const Products = lazy(() => import("./pages/Products/Products"));
const Faqs = lazy(() => import("./pages/Faqs/Faqs"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy/PrivacyPolicy"));
const TermsAndConditions = lazy(() =>
  import("./pages/TermsAndConditions/TermsAndConditions")
);
// const Recipes = lazy(() => import("./pages/Recipes/Recipes"));
// const Footer = lazy(() => import("./components/Footer/Footer"));

const Routing = () => {
  return (
    <>
      <Header />
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path={homeURL} element={<Home />} />
          <Route path={aboutURL} element={<About />} />
          <Route path={contactUsURL} element={<ContactUs />} />
          <Route path={whereToBuyURL} element={<WhereToBuy />} />
          {/* <Route path={recipesURL} element={<Recipes />} /> */}
          <Route path={productsURL} element={<Products />} />
          <Route path={faqsURL} element={<Faqs />} />
          <Route path={privacyPolicyURL} element={<PrivacyPolicy />} />
          <Route path={termsURL} element={<TermsAndConditions />} />
          {/* <Route exact path="/404" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/404" />} /> */}
        </Routes>
      </Suspense>
      {/* <Footer /> */}
    </>
  );
};
export default Routing;
