import React from "react";
import "./loader.scss";

const Loader = () => {
  return (
    <>
      <section className="loader_sec">
        <ul className="loader_list">
          <li className="loader_list_item" id="a"></li>
          <li className="loader_list_item" id="b"></li>
          <li className="loader_list_item" id="c"></li>
          <li className="loader_list_item" id="d"></li>
          <li className="loader_list_item" id="e"></li>
          <li className="loader_list_item" id="f"></li>
          <li className="loader_list_item" id="g"></li>
          <li className="loader_list_item" id="h"></li>
          <li className="loader_list_item" id="i"></li>
        </ul>
      </section>
    </>
  );
};

export default Loader;
